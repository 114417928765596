import apiClient from '../axios'

export const processPayment = async (payload) => {
  return apiClient
    .post('/payments', payload)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    })
}

export default processPayment
