import { notification } from 'antd'
import { all, takeLatest, put, call } from 'redux-saga/effects'
import { processPayment } from 'services/payment'
import actions from './actions'

export function* PROCESS_PAYMENT({ payload }) {
  // call backend service
  yield put({
    type: 'payment/SET_STATE',
    payload: {
      status: 'loading',
      data: null,
    },
  })
  const response = yield call(processPayment, payload)

  if (response.status === 200) {
    yield put({
      type: 'payment/SET_STATE',
      payload: {
        status: 'success',
        data: response.data.payment,
      },
    })
  } else if (response.status === 400) {
    notification.error('Error Processing Transaction! Please try again')
    yield put({
      type: 'payment/SET_STATE',
      payload: {
        status: 'error',
        data: response.data.error,
      },
    })
  } else {
    yield put({
      type: 'payment/SET_STATE',
      payload: {
        status: 'error',
        data: response.data.error,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.PROCESS_PAYMENT, PROCESS_PAYMENT)])
}
