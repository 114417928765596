import axios from 'axios'
// import store from 'store'
// import { notification } from 'antd'

console.log(process.env.REACT_APP_BACKEND_URL)

const apiClient = axios.create({
  // baseURL: 'https://payment-dev.testcoders.com/',
  baseURL: process.env.REACT_APP_BACKEND_URL,
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

// apiClient.interceptors.request.use((request) => {
//   const accessToken = store.get('accessToken')
//   if (accessToken) {
//     request.headers.Authorization = `Bearer ${accessToken}`
//     request.headers.AccessToken = accessToken
//   }
//   return request
// })

// apiClient.interceptors.response.use(undefined, (error) => {
//   // Errors handling
//   const { response } = error
//   const { data } = response
//   if (data) {
//     notification.warning({
//       message: data,
//     })
//   }
// })

export default apiClient
